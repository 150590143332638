import React, { Component } from 'react';
import axios from 'axios';
import logo from './assets/gtb-logo.png';
import crea8Logo from './assets/gtcrea8-logo.png';
import orangeLoader from './assets/orange-loader.svg';
import successIcon from './assets/success.png';
import failureIcon from './assets/failure.png';
import './App.css';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import pageGraphics from './assets/zero-percent.png';

class App extends Component {
  state = {
    section: 'personal',
    answers: 0,
    answered: 0,
    alertMessage: '',
    alertType: '',
    alert: false,
    overlay: false,
    graphic: true,
    schoolDrop: false,
    courseDrop: false,
    levelDrop: false,
    yearDrop: false,
    genderDrop: false,
    gradeYear: new Date().getFullYear(),
    selectedDate: null,
    passportLoaded: false,
    signatureLoaded: false,
    otherIDLoaded: false,
    schoolIDLoaded: false,
    creating: false,
    completed: false,
    accountDetails: null,
    schools: [],
    schoolList: [],
    courses: [
      { course: 'Social Sciences'},
      { course: 'Engineering'},
      { course: 'Law'},
      { course: 'Physical Sciences'},
      { course: 'Business Administration'},
    ],
    levels: [
      { level: 'Jamb Enrollee'},
      { level: 'Prelim'},
      { level: 'Diploma'},
      { level: '100L'},
      { level: '200L'},
      { level: '300L'},
      { level: '400L'},
      { level: '500L'},
    ],
    firstName: '', lastName: '', address: '', emailAddress: '', phoneNumber: '', 
    gender: '', motherMaiden: '', nok: '', dob: null, schoolName: '', courseName: '', 
    gradYear: '', levelName: '', passport: '', signature: '', schoolID: '', otherID: '',
    firstNameMarker: false, lastNameMarker: false, addressMarker: false, emailAddressMarker: false,
    phoneNumberMarker: false, genderMarker: false, motherMaidenMarker: false, nokMarker: false,
    dobMarker: false, schoolNameMarker: false, courseNameMarker: false, gradYearMarker: false,
    levelNameMarker: false, passportMarker: false,
    headers: { 'Content-Type': 'application/json', }
  }

  componentDidMount() {
    axios
      .get('./schools.json')
      .then(res => {
        const schoolList = res.data;
        this.setState({ schoolList, schools: res.data });
      })
      .catch(err => console.log(err.message));
  }

  switchSection = (value) => { this.setState({ section: value }) }

  hideOverlay = () => {
    this.setState({
      overlay: false, firstName: '', lastName: '', address: '', emailAddress: '', phoneNumber: '', 
      gender: '', motherMaiden: '', nok: '', dob: null, schoolName: '', courseName: '', answers: 0,
      gradYear: '', levelName: '', passport: '', signature: '', schoolID: '', otherID: '', answered: 0,
    }) 
  }

  setSchoolName = (schoolName) => { 
    this.setState({ schoolName })
    this.hideSchoolDrop();

    setTimeout(() => {
      if (this.state.schoolName.length >= 3 && !this.state.schoolNameMarker) {
        this.setState({ answered: this.state.answered += 7.7, answers: this.state.answers += 1, schoolNameMarker: true })
      } else if (this.state.schoolName.length < 3 && this.state.schoolNameMarker) {
        this.setState({ answered: this.state.answered -= 7.7, answers: this.state.answers -= 1, schoolNameMarker: false })
      }
    }, 100)
  }
  setCourseName = (courseName) => { 
    this.setState({ courseName }); 
    this.hideCourseDrop(); 

    setTimeout(() => {
      if (this.state.courseName.length >= 3 && !this.state.courseNameMarker) {
        this.setState({ answered: this.state.answered += 7.7, answers: this.state.answers += 1, courseNameMarker: true })
      } else if (this.state.courseName.length < 3 && this.state.courseNameMarker) {
        this.setState({ answered: this.state.answered -= 7.7, answers: this.state.answers -= 1, courseNameMarker: false })
      }
    }, 100)
  }
  setGender = (gender) => {
    this.setState({ gender }); 
    this.hideGenderDrop(); 

    setTimeout(() => {
      if (this.state.gender && !this.state.genderMarker) {
        this.setState({ answered: this.state.answered += 7.7, answers: this.state.answers += 1, genderMarker: true })
      } else if (!this.state.gender && this.state.genderMarker) {
        this.setState({ answered: this.state.answered -= 7.7, answers: this.state.answers -= 1, genderMarker: false })
      }
    }, 100)
  }
  setLevel = (levelName) => { 
    this.setState({ levelName }); 
    this.hideLevelDrop(); 
    
    setTimeout(() => {
      if (this.state.levelName && !this.state.levelNameMarker) {
        this.setState({ answered: this.state.answered += 7.7, answers: this.state.answers += 1, levelNameMarker: true })
      } else if (!this.state.levelName && this.state.levelNameMarker) {
        this.setState({ answered: this.state.answered -= 7.7, answers: this.state.answers -= 1, levelNameMarker: false })
      }
    }, 100)
  }
  setGradYear = (gradYear) => { this.setState({ gradYear }); this.hideYearDrop(); }

  toggleSchoolDrop = () => { this.setState({ schoolDrop: !this.state.schoolDrop }) }
  toggleGenderDrop = () => { this.setState({ genderDrop: !this.state.genderDrop }) }
  toggleCourseDrop = () => { this.setState({ courseDrop: !this.state.courseDrop }) }
  toggleLevelDrop = () => { this.setState({ levelDrop: !this.state.levelDrop }) }
  toggleYearDrop = () => { this.setState({ yearDrop: !this.state.yearDrop }) }

  hideGenderDrop = () => { setTimeout(() => { this.setState({ genderDrop: false }) }, 100); }
  hideSchoolDrop = () => { setTimeout(() => { this.setState({ schoolDrop: false }) }, 100) }
  hideYearDrop = () => { setTimeout(() => { this.setState({ yearDrop: false }) }, 100) }
  hideCourseDrop = () => { setTimeout(() => { this.setState({ courseDrop: false }) }, 100) }
  hideLevelDrop = () => { setTimeout(() => { this.setState({ levelDrop: false }) }, 100) }

  handleDateChange = e => {
    // const dateValues = new Date(e).toLocaleDateString().split('/')
    // `${dateValues[1]}/${dateValues[0]}/${dateValues[2]}`
    const month = new Date(e).getMonth() + 1
    const day = new Date(e).getDate()
    const year = new Date(e).getFullYear()
    const dob = `${month < 10 ? '0' + String(month) : month }/${day < 10 ? '0' + String(day) : day }/${year}`

    // console.log(`${month < 10 ? '0' + String(month) : month }/${day < 10 ? '0' + String(day) : day }/${year}`)
    this.setState({ selectedDate: e, dob })

    // const dateValues = new Date(e).toLocaleDateString().split('/')
    // console.log(`${dateValues[1]}/${dateValues[0]}/${dateValues[2]}`)

    setTimeout(() => {
      if (this.state.dob && !this.state.dobMarker) {
        this.setState({ answered: this.state.answered += 7.7, answers: this.state.answers += 1, dobMarker: true })
      } else if (!this.state.dob && this.state.dobMarker) {
        this.setState({ answered: this.state.answered -= 7.7, answers: this.state.answers -= 1, dobMarker: false })
      }
    }, 100)
  }

  filterSchools(e) {
    // console.log(this.accountForm.value.schoolName);
    // this.setState({ [e.target.name]: e.target.value })
    this.setSchoolName(e.target.value)
    const schoolname = this.state.schoolName.toLowerCase();
    this.setState({ 
      schoolDrop: true,
      schools: this.state.schoolList.filter(school => school.school.toLowerCase().indexOf(schoolname) !== -1)
    })
  }

  // createAccount = (e) => { e.preventDefault(); console.log('creating account..') }
  createAccount = (e) => { 
    e.preventDefault();
    
    const { 
      firstName, lastName, address, emailAddress, phoneNumber, gender, motherMaiden, nok, dob, schoolName, 
      courseName, gradYear, levelName, passport, signature, schoolID, otherID, creating,
    } = this.state;

    if (!creating) {
      this.setState({ creating: true })
      
      const details = {
        AccountCategory: 'Under Graduate',
        Address: address ? address : '',
        AuthMode: 'MPIN',
        AuthValue: '1234',
        Bvn: '',
        Channel: 'GTCREA8',
        Course: courseName ? courseName : '',
        DoB: dob ? dob : '',
        Email: emailAddress ? emailAddress : '',
        FirstName: firstName ? firstName : '',
        Gender: gender === 'Male' ? '1' : gender === 'Female' ? '2' : '',
        GradYear: gradYear ? String(gradYear) : '',
        JambSlip: '',
        LastName: lastName ? lastName : '',
        Level: levelName ? levelName : '',
        MobileNo: phoneNumber ? phoneNumber : '',
        MotherMaiden: motherMaiden ? motherMaiden : '',
        NextOfKin: nok ? nok : '',
        OtherID: otherID ? otherID : '',
        Passport: passport ? passport : '',
        RequestId: '12345662122',
        SchoolID: schoolID ? schoolID : '',
        SchoolName: schoolName ? schoolName : '',
        SessionId: 'gtHub0123456189',
        USerID: '20515233801',
        UserId: '0000',
        signature: signature ? signature : ''
      }

      // console.log(details)
      
      axios.post('https://kingsguard.herokuapp.com/user/login', details, { headers: this.state.headers })
      .then(res => console.log(res.data)).catch(err => console.log(err.message))

      axios
        .post('https://collection.gtbank.com/AppServices/AccountOpening/Api/GTCrea8', details, { headers: this.state.headers })
        .then(result => {
          //  console.log(result.data)
          this.setState({
            accountDetails: result.data,
            creating: false,
            overlay: true,
            alertType: 'success'
          })
        })
        .catch(err => {
          console.log(err.message)
          this.setState({
            creating: false,
            overlay: true,
            alertType: 'failure'
          })
        })
      // console.log('working hard to create your account!')
    }
    
  }
  
  showAlertMessage = (msg) => {
    this.setState({ alert: true, alertMessage: msg })
    setTimeout(() => {
      this.setState({ alert: false, alertMessage: `` })
    }, 2000)
  }

  // onFileUpload = (e) => { console.log(e.target.files[0]) }
  onFileUpload = (e, type) => {
    let reader = new FileReader()
    reader.readAsDataURL(e.target.files[0])

    reader.onload = () => {
      const base64File = String(reader.result).split(';base64,')

      switch(type) {
        case 'passport':
          // let image = new Image();
          // image.onload = () => console.log(`Passport picture (${this.width}x${this.height}) added successfully!`)
          // image.onload = () => this.showAlertMessage(`Passport picture (${image.width}x${image.height}) added successfully!`)
          // console.log(`Passport picture (${image.width}x${image.height}) added successfully!`)

          this.setState({ passport: base64File[1], passportLoaded: true })
          this.showAlertMessage(`Passport picture added successfully!`)
          setTimeout(() => {
            if (this.state.passport && !this.state.passportMarker) {
              this.setState({ answered: this.state.answered += 7.7, answers: this.state.answers += 1, passportMarker: true })
            } else if (!this.state.passport && this.state.passportMarker) {
              this.setState({ answered: this.state.answered -= 7.7, answers: this.state.answers -= 1, passportMarker: false })
            }
          }, 100)
          break;
        case 'signature':
          this.setState({ signature: base64File[1], signatureLoaded: true })
          this.showAlertMessage(`Passport picture added successfully!`)
          break;
        case 'school':
          this.setState({ schoolID: base64File[1], schoolIDLoaded: true })
          this.showAlertMessage(`Passport picture added successfully!`)
          break;
        case 'other':
          this.setState({ otherID: base64File[1], otherIDLoaded: true })
          this.showAlertMessage(`Passport picture added successfully!`)
          break;
        default:
          this.showAlertMessage(`Error! Please reload the page and try again`)
          break;
      }
    }
  }

  handleInputChange = (e) => { 
    this.setState({ [e.target.name]: e.target.value });

    if (e.target.name === 'firstName') {
      if (e.target.name.length >= 3 && !this.state.firstNameMarker) {
        this.setState({ answered: this.state.answered += 7.7, answers: this.state.answers += 1, firstNameMarker: true })
      } else if (e.target.name.length < 3 && this.state.firstNameMarker) {
        this.setState({ answered: this.state.answered -= 7.7, answers: this.state.answers -= 1, firstNameMarker: false })
      }
    }
    if (e.target.name === 'lastName') {
      if (e.target.name.length >= 3 && !this.state.lastNameMarker) {
        this.setState({ answered: this.state.answered += 7.7, answers: this.state.answers += 1, lastNameMarker: true })
      } else if (e.target.name.length < 3 && this.state.lastNameMarker) {
        this.setState({ answered: this.state.answered -= 7.7, answers: this.state.answers -= 1, lastNameMarker: false })
      }
    }
    if (e.target.name === 'address') {
      if (e.target.name.length >= 3 && !this.state.addressMarker) {
        this.setState({ answered: this.state.answered += 7.7, answers: this.state.answers += 1, addressMarker: true })
      } else if (e.target.name.length < 3 && this.state.addressMarker) {
        this.setState({ answered: this.state.answered -= 7.7, answers: this.state.answers -= 1, addressMarker: false })
      }
    }
    if (e.target.name === 'emailAddress') {
      if (e.target.name.length >= 3 && !this.state.emailAddressMarker) {
        this.setState({ answered: this.state.answered += 7.7, answers: this.state.answers += 1, emailAddressMarker: true })
      } else if (e.target.name.length < 3 && this.state.emailAddressMarker) {
        this.setState({ answered: this.state.answered -= 7.7, answers: this.state.answers -= 1, emailAddressMarker: false })
      }
    }
    if (e.target.name === 'phoneNumber') {
      if (e.target.name.length >= 3 && !this.state.phoneNumberMarker) {
        this.setState({ answered: this.state.answered += 7.7, answers: this.state.answers += 1, phoneNumberMarker: true })
      } else if (e.target.name.length < 3 && this.state.phoneNumberMarker) {
        this.setState({ answered: this.state.answered -= 7.7, answers: this.state.answers -= 1, phoneNumberMarker: false })
      }
    }
    if (e.target.name === 'gender') {
      if (e.target.name.length >= 3 && !this.state.genderMarker) {
        this.setState({ answered: this.state.answered += 7.7, answers: this.state.answers += 1, genderMarker: true })
      } else if (e.target.name.length < 3 && this.state.genderMarker) {
        this.setState({ answered: this.state.answered -= 7.7, answers: this.state.answers -= 1, genderMarker: false })
      }
    }
    if (e.target.name === 'motherMaiden') {
      if (e.target.name.length >= 3 && !this.state.motherMaidenMarker) {
        this.setState({ answered: this.state.answered += 7.7, answers: this.state.answers += 1, motherMaidenMarker: true })
      } else if (e.target.name.length < 3 && this.state.motherMaidenMarker) {
        this.setState({ answered: this.state.answered -= 7.7, answers: this.state.answers -= 1, motherMaidenMarker: false })
      }
    }
    if (e.target.name === 'nok') {
      if (e.target.name.length >= 3 && !this.state.nokMarker) {
        this.setState({ answered: this.state.answered += 7.7, answers: this.state.answers += 1, nokMarker: true })
      } else if (e.target.name.length < 3 && this.state.nokMarker) {
        this.setState({ answered: this.state.answered -= 7.7, answers: this.state.answers -= 1, nokMarker: false })
      }
    }
  }

  render() {
    const {
      section, answers, answered, alertMessage, genderDrop, schoolDrop, courseDrop, levelDrop, yearDrop, 
      gradeYear, creating, courses, levels, firstName, lastName, address, emailAddress, phoneNumber, schools,
      gender, motherMaiden, nok, schoolName, courseName, gradYear, levelName, alert, passportLoaded,
      signatureLoaded, otherIDLoaded, schoolIDLoaded, selectedDate, graphic, overlay, accountDetails,
    } = this.state;

    return (
      <>
      <div className="screen">
        <div className="logo">
          <img src={logo} alt="GTBank Logo" />
        </div>
  
        <div className="container">
          <div className="menu">
            <div onClick={() => this.switchSection('personal')} className={section === 'personal' ? 'menu-item active' : 'menu-item'}>
              <p className="menu-account">1</p>
              <p className="menu-text">Personal Details</p>
            </div>
            <div onClick={() => this.switchSection('school')} className={section === 'school' ? 'menu-item active' : 'menu-item'}>
              <p className="menu-account">2</p>
              <p className="menu-text">School Details</p>
            </div>
            <div onClick={() => this.switchSection('document')} className={section === 'document' ? 'menu-item active' : 'menu-item'}>
              <p className="menu-account">3</p>
              <p className="menu-text">Upload Documents</p>
            </div>
          </div>

          <div className="content-area">
            <div className="content">
              <div className="content-top">
                <div className="gtc-logo">
                  <small>Bank Free with a</small>
                  <img src={crea8Logo} alt="GTCrea8 Logo" />
                </div>
                
                <div className="progress-bar">
                  <p>{ answers } of 13 answered</p>
                  <p className="bar"><span style={{ width: answered + '%' }}></span></p>
                </div>
              </div>

              <form onSubmit={e => this.createAccount(e)}>
                <div className="messages">
                  <p className="required-msg">All fields marked with * are compulsory</p>
                  {/* <p className="alert">{ alertMessage }</p> */}
                  { alert && <p className="alert">{ alertMessage }</p> }
                </div>

                <div className={section === 'personal' ? 'active section-band' : 'section-band'} onClick={section !== 'personal' ? () => this.switchSection('personal') : null}>
                  <div className="band">
                    <p>Personal Details</p>
                    { section !== 'personal' && <i className="material-icons">expand_more</i> }
                  </div>
                </div>

                {
                  section === 'personal' &&
                  <div className="inputs personal">
                    <div className="input-double">
                      <div className="input-item">
                        <label>First Name <span className="required">*</span></label>
                        <input type="text" value={firstName} name="firstName" onChange={e => this.handleInputChange(e)} />
                      </div>
                      <div className="input-item">
                        <label>Last Name <span className="required">*</span></label>
                        <input type="text" value={lastName} name="lastName" onChange={e => this.handleInputChange(e)} />
                      </div>
                    </div>
                    <div className="input-single">
                      <div className="input-item">
                        <label>Home Address <span className="required">*</span></label>
                        <input type="text" value={address} name="address" onChange={e => this.handleInputChange(e)} />
                      </div>
                    </div>
                    <div className="input-double">
                      <div className="input-item">
                        <label>Email Address <span className="required">*</span></label>
                        <input type="email" value={emailAddress} name="emailAddress" onChange={e => this.handleInputChange(e)} />
                      </div>
                      <div className="input-item">
                        <label>Phone Number <span className="required">*</span></label>
                        <input type="number" value={phoneNumber} name="phoneNumber" onChange={e => this.handleInputChange(e)} />
                      </div>
                    </div>
                    <div className="input-double">
                      <div className="input-item">
                        <label>Gender <span className="required">*</span></label>
                        <input 
                          type="text" value={gender} name="gender" 
                          // onChange={e => this.handleInputChange(e)} 
                          onClick={() => this.toggleGenderDrop()} 
                          readOnly
                          // onFocus={() => this.toggleGenderDrop()} 
                          // onBlur={() => this.hideGenderDrop()} 
                        />
                        {
                          genderDrop &&
                          <div className="drop-down">
                            <p onClick={() => this.setGender('Male')}>{ 'Male' }</p>
                            <p onClick={() => this.setGender('Female')}>{ 'Female' }</p>
                          </div>
                        }
                      </div>
                      <div className="input-item">
                        <label>Mother's Maiden Name <span className="required">*</span></label>
                        <input type="text" value={motherMaiden} name="motherMaiden" onChange={e => this.handleInputChange(e)} />
                      </div>
                    </div>
                    <div className="input-double">
                      <div className="input-item">
                        <label>Next of Kin <span className="required">*</span></label>
                        <input type="text" value={nok} name="nok" onChange={e => this.handleInputChange(e)} />
                      </div>
                      <div className="input-item">
                        <label>Date of Birth <span className="required">*</span></label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            fullWidth
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="date-picker-dialog"
                            // label="Date picker dialog"
                            value={selectedDate}
                            onChange={e => this.handleDateChange(e)}
                            placeholder='MM/DD/YYYY'
                            KeyboardButtonProps = {{
                              'aria-label': 'change date',
                            }}
                          />
                      </MuiPickersUtilsProvider>
                        {/* <input type="text" value={dob} name="dob" onChange={e => this.handleInputChange(e)} /> */}
                      </div>
                    </div>
                  </div>
                }

                <div className={section === 'school' ? 'active section-band' : 'section-band'} onClick={section !== 'school' ? () => this.switchSection('school') : null}>
                  <div className="band">
                    <p>School Details</p>
                    { section !== 'school' && <i className="material-icons">expand_more</i>}
                  </div>
                </div>

                {
                  section === 'school' &&
                  <div className="inputs school">
                    <div className="input-single">
                      <div className="input-item">
                        <label>School Name <span className="required">*</span></label>
                        <input 
                          placeholder="Start typing to find your school.."  
                          type="text" value={schoolName} name="schoolName" 
                          onChange={e => this.filterSchools(e)}
                          onClick={() => this.toggleSchoolDrop()}
                          // onBlur={() => this.hideSchoolDrop()}
                        />
                        { 
                          schoolDrop && 
                          <div className="drop-down">
                            {
                              schools.map((school, index) => (
                                <p key={index} onClick={() => this.setSchoolName(school.school)}>{school.school}</p>
                              ))
                            }
                          </div>
                        }
                      </div>
                    </div>
                    <div className="input-single">
                      <div className="input-item">
                        <label>Course of Study <span className="required">*</span></label>
                        <input 
                          type="text" value={courseName} name="courseName" 
                          onChange={e => this.handleInputChange(e)} 
                          onClick={() => this.toggleCourseDrop()} 
                          // onBlur={() => this.hideCourseDrop()} 
                          readOnly
                        />
                        {
                          courseDrop &&
                          <div className="drop-down">
                            {
                              courses.map((course, index) => (
                                <p key={index} onClick={() => this.setCourseName(course.course)}>{course.course}</p>
                              ))
                            }
                          </div>
                        }
                      </div>
                    </div>
                    <div className="input-double">
                      <div className="input-item">
                        <label>Expected Year of Graduation</label>
                        <input 
                          type="text" value={gradYear} name="gradYear" 
                          onChange={e => this.handleInputChange(e)} 
                          onClick={() => this.toggleYearDrop()} readOnly
                          // onBlur={() => this.hideYearDrop()} 
                        />
                        {
                          yearDrop &&
                          <div className="drop-down">
                            <p onClick={() => this.setGradYear(gradeYear)}>{ gradeYear }</p>
                            <p onClick={() => this.setGradYear(gradeYear + 1)}>{ gradeYear + 1 }</p>
                            <p onClick={() => this.setGradYear(gradeYear + 2)}>{ gradeYear + 2 }</p>
                            <p onClick={() => this.setGradYear(gradeYear + 3)}>{ gradeYear + 3 }</p>
                            <p onClick={() => this.setGradYear(gradeYear + 4)}>{ gradeYear + 4 }</p>
                            <p onClick={() => this.setGradYear(gradeYear + 5)}>{ gradeYear + 5 }</p>
                            <p onClick={() => this.setGradYear(gradeYear + 6)}>{ gradeYear + 6 }</p>
                          </div>
                        }
                      </div>
                      <div className="input-item">
                        <label>Level <span className="required">*</span></label>
                        <input 
                          type="text" value={levelName} name="levelName" 
                          onChange={e => this.handleInputChange(e)}
                          onClick={() => this.toggleLevelDrop()} readOnly
                          // onBlur={() => this.hideLevelDrop()}
                        />
                        {
                          levelDrop &&
                          <div className="drop-down">
                            {
                              levels.map((level, index) => (
                                <p key={index} onClick={() => this.setLevel(level.level)}>{level.level}</p>
                              ))
                            }
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                }

                <div className={section === 'document' ? 'active section-band' : 'section-band'} onClick={section !== 'document' ? () => this.switchSection('document') : null}>
                  <div className="band">
                    <p>Upload Documents</p>
                    { section !== 'document' && <i className="material-icons">expand_more</i>}
                  </div>
                </div>
          
                {
                  section === 'document' &&
                  <div className="inputs document">
                    <div className="input-double">
                      <div className="input-item">
                        <label className={passportLoaded ? 'active' : ''}>
                          <i className="material-icons">cloud_upload</i>
                          <p>Upload Passport Picture <span className="required">*</span></p>
                          <input type="file" name="passport" onChange={(e) => this.onFileUpload(e, 'passport')} />
                        </label>
                      </div>
                      <div className="input-item">
                        <label className={signatureLoaded ? 'active' : ''}>
                          <i className="material-icons">cloud_upload</i>
                          <p>Upload Signature</p>
                          <input type="file" name="signature" onChange={(e) => this.onFileUpload(e, 'signature')} />
                        </label>
                      </div>
                    </div>
                    <div className="input-double">
                      <div className="input-item">
                        <label className={schoolIDLoaded ? 'active' : ''}>
                          <i className="material-icons">cloud_upload</i>
                          <p>Upload School ID</p>
                          <input type="file" name="school" onChange={(e) => this.onFileUpload(e, 'school')} />
                        </label>
                      </div>
                      <div className="input-item">
                        <label className={otherIDLoaded ? 'active' : ''}>
                          <i className="material-icons">cloud_upload</i>
                          <p>Upload Other ID</p>
                          <input type="file" name="other" onChange={(e) => this.onFileUpload(e, 'other')} />
                        </label>
                      </div>
                    </div>
                  </div>
                }

                <div className="submit-button">
                  {/* disabled={!accountForm.valid} */}
                  <button type="submit" disabled={answers !== 13}>Create Account</button>
                  { 
                    creating &&
                    <div className="creating">
                      <img src={orangeLoader} alt="Loading Icon" />
                      <p>Creating Your Account..</p>
                    </div>
                  }
                </div>
              </form>
            </div>

            {
              graphic && 
              <div className="graphics">
                <img src={pageGraphics} alt="zero percent interest" />
              </div>
            }
          </div>
        </div>
      </div>

      {
        overlay &&
        <div className="overlay">
          {
            accountDetails.ResponseCode === '00' && accountDetails.AccountNumber &&
            <div className="overlay-content">
              <img src={successIcon} alt="Account creation success" />
              <div className="text-area">
                <p className="top-text-area">Congratulations { firstName }! <br />Your account was created successfully..</p>
                <h2 className="account">Your GTCrea8 account number is { accountDetails.AccountNumber }</h2>
                <div className="actions">
                  <p onClick={() => this.hideOverlay()}>Create Another Account</p>
                  <button onClick={() => this.hideOverlay()} type="button">Fund Your Account</button>
                </div>
              </div>
            </div>
          }
          {
            accountDetails.ResponseCode !== '00' && !accountDetails.AccountNumber &&
            <div className="overlay-content">
              <img src={failureIcon} alt="Account creation error" />
              <div className="text-area">
                <p className="top-text-area">Error while creating account..</p>
                <h2 className="account-red">{ accountDetails.ResponseDescription }</h2>
                <div className="actions">
                  <p onClick={() => this.hideOverlay()}>Please Try Again</p>
                </div>
              </div>
            </div>
          }
        </div>
      }
      </>
    );
  }
}

export default App;